header {
  background: @header-bg radial-gradient(circle at center 30%, @header-bg-highlight 30%, @header-bg 90%);
  text-align: center;
  h1 {
    margin: 0 0 (@grid-gutter-width * 1.5) 0;
    color: @header-logo-color;
    font-weight: 700;
    margin-left: (@grid-gutter-width / 2 * -1);
    margin-right: (@grid-gutter-width / 2 * -1);
    > a > img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  nav {
    line-height: 1.2;
    > ul {
      list-style: none;
      padding: 0;
      white-space: nowrap;
      > li {
        display: inline-block;
        > a {
          font-size: @header-nav-font-size;
          color: @header-nav-color;
          background: @header-nav-bg;
          padding: @padding-large-vertical @padding-base-horizontal;
          border-top-left-radius: @header-nav-border-radius;
          border-top-right-radius: @header-nav-border-radius;
        }
        &.active, &.active:hover {
          > a {
            color: @header-nav-active-color;
            background: @header-nav-active-bg;
            cursor: default;
          }
        }
        &:hover {
          > a {
            color: @header-nav-hover-color;
            background: @header-nav-hover-bg;
            text-decoration: none;
          }
        }
      }
    }
    @media (max-width: @screen-xs-max) {
      position: fixed;
      top: @grid-gutter-width / 2;
      right: @grid-gutter-width / 2;
      z-index: 999;
      &:before {
        font-family: "Linearicons-Free";
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e871";
        font-size: @font-size-large;
        background: fade(@body-bg,90%);
        border-radius: @border-radius-base;
        padding: @padding-large-vertical @padding-large-horizontal;
        box-shadow: 0 2px 3px fade(@text-color,50%);
      }
      > ul {
        position: absolute;
        top: 32px;
        background: fade(@body-bg,90%);
        box-shadow: 0 2px 3px fade(@text-color,50%);
        border-radius: @border-radius-base;
        right: 0;
        line-height: 1.8;
        animation: fade-out .2s ease;
        display: none;
        > li {
          display: block;
          text-align: right;
          > a {
            padding: @padding-large-vertical @padding-large-horizontal;
          }
          &.active > a, &.active:hover > a {
            background: transparent;
          }
        }
      }
      &:hover, &.active {
        > ul {
          display: block;
          animation: fade-in .2s ease;
        }
      }
    }
  }
  @media(min-width: @screen-sm-min) {
    .fixed-menu & {
      position: fixed;
      z-index: 999;
      left: 0;
      width: 100%;
      top: -250px;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}