@import "../../node_modules/bootstrap/less/bootstrap.less";
@import (less) "../../node_modules/featherlight/src/featherlight.css";
@import (less) "../../node_modules/featherlight/src/featherlight.gallery.css";
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,400italic|PT+Serif:400,700);
@import url(https://cdn.linearicons.com/free/1.0.0/icon-font.min.css);

@import "variables.less";
@import "header.less";
@import "main.less";
@import "footer.less";

body {
  background: @footer-bg;
}