footer {
  background: @footer-bg;
  color: @footer-color;
  padding: @grid-gutter-width 0 (@grid-gutter-width * 2) 0;
  font-weight: 300;
  h2 {
    color: @footer-color;
    font-size: @font-size-h2 * 1.2;
    font-weight: 700;
  }
  .lead {
    font-size: @font-size-large * 1.1;
    line-height: 1.1;
    > small {
      font-size: @font-size-base;
    }
  }
  a {
    color: @footer-color;
    &:hover {
      color: @footer-color;
    }
  }
}